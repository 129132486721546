import Vue from 'vue'
import VueCompositionApi from "@vue/composition-api";
import {PiniaVuePlugin, createPinia} from 'pinia'

import vuetify from './plugins/vuetify'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import VueOffline from 'vue-offline'

Vue.use(VueOffline)

import './registerServiceWorker'

Vue.use(VueMoment, {
  moment,
})

Vue.use(VueCompositionApi);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();
Vue.use(pinia)


Vue.config.productionTip = false

const App = () => import('./App')
Vue.component('app', App);


new Vue({
  pinia, vuetify,
  render: h => h(App)
}).$mount('#app')

